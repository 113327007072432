/// <reference path="../../_all.ts"/>
/// <reference path="../../shared/interfaces/brookson.interfaces.window.ts" />
/// <reference path="../../shared/services/brookson.services.service-line.ts" />
/// <reference path="../../shared/services/brookson.services.member.ts" />
/// <reference path="../../shared/services/brookson.services.authentication.ts" />
/// <reference path="../../shared/modules/brookson.navigation.ts" />

module Login {
    export class BrooksonLoginSuper implements ng.IComponentOptions {
        public controller: Function = SuperCtrlController;
        public templateUrl: string = "src/app/login/views/super.html";
    }

    class SuperCtrlController {
        static $inject = ['$window', '$state', '$stateParams', 'serviceLineSrv', 'memberSrv', 'authenticationSrv', 'focus', 'brookson.navigation'];

        public memberId: string = "";
        public message: string = "";
        public isLoading: boolean = false;

        constructor(
            private $window: Window,
            private $state: ng.ui.IStateService,
            private $stateParams: ng.ui.IStateParamsService,
            private serviceLineSrv: Shared.IServiceLineService,
            private memberSrv: Shared.IMemberService,
            private authenticationSrv: Shared.IAuthenticationService,
            private focus: any,
            private brooksonNavigation: Shared.IBrooksonNavigation
        ) { }

        $onInit = (): void => {
            this.focusOnMemberInput();
        }

        submit = (isValid: boolean): void => {
            if (isValid && !this.isLoading) {
                let input = <Shared.MemberModel>{
                    memberId: this.memberId
                };

                let member: Shared.MemberModel = this.memberSrv.getMember();

                if (!member.isAdmin) {
                    this.message = "Cannot verify as administrator!";
                    return;
                }

                const memberIdCopy = angular.copy(this.memberId);

                this.isLoading = true;

                this.serviceLineSrv.getServiceLines(input)
                    .then((lines): void => {
                        if (lines.length) {
                            member.memberId = memberIdCopy;
                            member.serviceLines = lines;

                            let hasMultipleServiceLines = _.keys(_.groupBy(lines, 'customerReference')).length > 1;

                            if (hasMultipleServiceLines) {
                                this.$state.go('login.serviceLine', this.$stateParams);
                            } else {
                                member.businessType = lines[0].businessType;
                                member.role = lines[0].role;
                                member.companyRef = lines[0].customerReference;

                                this.authenticationSrv.updateAccessTokenServiceLine(member.memberId, member.serviceLines[0], member.refreshToken, member.apiKeySource)
                                    .then((data): void => {
                                        // If is leaver, go to self assessment
                                        if (member.serviceLines[0].isEligible) {
                                            this.brooksonNavigation.personalTax.selfAssessment();
                                            return;
                                        }

                                        if (this.$stateParams['ReturnUrl']) {
                                            this.$window.location.href = this.$stateParams['ReturnUrl'];
                                        } else {
                                            this.brooksonNavigation.dashboard.main();
                                        }

                                        if (member.businessType.toLowerCase() === 'umbrella' && !this.$window.appSettings.umbEnabled) {
                                            this.$window.location.href = this.$window.appSettings.connect2Url + '?lo=a25';
                                        }
                                    }, (err) => {
                                        this.message = err.data.error_description;
                                        this.focusOnMemberInput();
                                    });
                            }

                            this.memberSrv.setMember(member);
                        } else {
                            this.message = "Unable to find service lines for user.";
                            this.focusOnMemberInput();
                        }
                    }, (err): void => {
                        this.message = err.error_description;
                        this.focusOnMemberInput();
                    })
                    .then((): void => {
                        this.isLoading = false;
                    });
            } else {
                this.message = "Form is invalid!";
                this.focusOnMemberInput();
            }
        }

        focusOnMemberInput = (): void => {
            this.focus('memberId');
        }
    }
}