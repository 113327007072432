/// <reference path="../../_all.ts"/>
/// <reference path="../../shared/services/brookson.services.authentication.ts" />
/// <reference path="../../shared/services/brookson.services.member.ts" />
/// <reference path="../../shared/services/brookson.services.service-line.ts" />
/// <reference path="../../shared/modules/brookson.enums.ts" />

module Login {
    export class BrooksonLoginPasswordReset implements ng.IComponentOptions {
        public controller: Function = PasswordResetCtrlController;
        public templateUrl: string = "src/app/login/views/passwordReset.html";
    }

    export interface AppSettings extends ng.IWindowService {
        appSettings: any;
    }
    
    class PasswordResetCtrlController {
        static $inject = ["$state", "$stateParams", "authenticationSrv", "memberSrv", 'serviceLineSrv', 'brookson.navigation', 'contact.enums', "$window"];

        public username: string = "";
        public password: string = "";
        public confirmPassword: string = "";
        public message: string = null;

        constructor(
            private $state: ng.ui.IStateService,
            private $stateParams: ng.ui.IStateParamsService,
            private authenticationSrv: Shared.IAuthenticationService,
            private memberSrv: Shared.IMemberService,
            private serviceLineSrv: Shared.IServiceLineService,
            private brooksonNavigation: Shared.IBrooksonNavigation,
            private enums: Shared.IContactEnums,
            private $window: AppSettings,
        ) { 
            if(this.$window.appSettings.isIntouchSite)
                {
                    this.enums.ContactInformation.CustomerServicesPhone = '01202 901951';
                }    
        }

        passwordStrengthValidation = (password: string): boolean => {
            if (password.length < 8) {
                this.message = "Password must be a least 8 characters long.";
                return false;
            }

            return true;
        }

        setNewPassword = (): void => {
            let resetId = this.$stateParams['id'];

            if (this.password !== this.confirmPassword) {
                this.message = "The confirmation password must match.";
                return;
            }

            if (this.passwordStrengthValidation(this.password)) {
                this.message = null;
                this.authenticationSrv
                    .resetMemberPassword(resetId, this.username, this.password)
                    .then((data): void => {
                        if (data) {
                            this.authenticationSrv.login({
                                userName: this.username,
                                password: this.password
                            }).then((result): void => {
                                let member: Shared.MemberModel = this.memberSrv.setMember(result);

                                if (member.isAdmin) {
                                    this.$state.go("login.super");
                                } else {
                                    this.serviceLineSrv.getServiceLines(member).then((lines): void => {
                                        if (lines.length) {
                                            member.serviceLines = lines;
                                            if (lines.length > 1) {
                                                this.$state.go("login.serviceLine");
                                            } else {
                                                member.companyRef = lines[0].customerReference;
                                                this.authenticationSrv
                                                    .updateAccessTokenServiceLine(member.memberId, member.serviceLines[0], member.refreshToken, member.apiKeySource)
                                                    .then((data): void => {
                                                        this.brooksonNavigation.dashboard.main();
                                                    })
                                                    .catch((): void => { });
                                            }

                                            this.memberSrv.setMember(member);
                                        } else {
                                            this.message = "Login failed. Your password has been changed but we were unable to log you back in. Please call us on " + this.enums.ContactInformation.CustomerServicesPhone + " for assistance.";
                                        }
                                    }, (err): void => {
                                        this.message = err.error_description;
                                    });
                                }
                            },
                                (err): void => {
                                    this.message = "Login failed. Your password has been changed but we were unable to log you back in. Please call us on " + this.enums.ContactInformation.CustomerServicesPhone + " for assistance.";
                                });
                        } else {
                            this.message = "Activation failed. Please call us on " + this.enums.ContactInformation.CustomerServicesPhone + " for assistance.";
                        }
                    });
            }
        }
    }
}