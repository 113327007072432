/// <reference path="../_all.ts" />

module Login {
    export class StateConfig {
        constructor(
            private $stateProvider: ng.ui.IStateProvider,
            private $urlRouterProvider: ng.ui.IUrlRouterProvider
        ) {
            this.init();
        }

        private init(): void {
            this.$stateProvider
                .state("login",
                {
                    template: "<ui-view/>"
                })
                .state("login.login",
                {
                    url: "/Login?ReturnUrl",
                    template: "<brookson-login-login></brookson-login-login>"
                })
                .state("login.super",
                {
                    url: "/Login/Super?ReturnUrl",
                    template: "<brookson-login-super></brookson-login-super>"
                })
                .state("login.serviceLine",
                {
                    url: "/Login/ServiceLine?ReturnUrl",
                    template: "<brookson-login-service-line></brookson-login-service-line>"
                })
                .state("login.registration",
                {
                    url: "/Login/Registration",
                    template: "<brookson-login-registration></brookson-login-registration>"
                })
                .state("login.passwordReset",
                {
                    url: "/Login/PasswordReset/:id",
                    template: "<brookson-login-password-reset></brookson-login-password-reset>"
                })
                .state("login.forgottenPassword",
                {
                    url: "/Login/ForgottenPassword",
                    template: "<brookson-login-forgotten-password></brookson-login-forgotten-password>"
                })
                .state("login.shareholder",
                {
                    url: "/Login/Shareholder",
                    template: "<brookson-login-shareholder></brookson-login-shareholder>"
                });

            this.$urlRouterProvider.otherwise("/Login");
        }
    }
}