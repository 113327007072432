/// <reference path="../_all.ts"/>
/// <reference path="./app.login.state.ts" />
/// <reference path="./components/brookson.login.forgotten-password.ts" />
/// <reference path="./components/brookson.login.login.ts" />
/// <reference path="./components/brookson.login.password-reset.ts" />
/// <reference path="./components/brookson.login.registration.ts" />
/// <reference path="./components/brookson.login.service-line.ts" />
/// <reference path="./components/brookson.login.super.ts" />
/// <reference path="./components/brookson.login.shareholder.ts" />

module Login {
    export class App {
        static createModule(angular: ng.IAngularStatic) {
            angular.module('app.login', [
                'app.shared'
            ])
                .value('siloName', 'login')
                .config([
                    "$stateProvider", "$urlRouterProvider", ($stateProvider, $urlRouterProvider) => {
                        return new Login.StateConfig($stateProvider, $urlRouterProvider);
                    }
                ])
                .component('brooksonLoginForgottenPassword', new Login.BrooksonLoginForgottenPassword())
                .component('brooksonLoginLogin', new Login.BrooksonLoginLogin())
                .component('brooksonLoginPasswordReset', new Login.BrooksonLoginPasswordReset())
                .component('brooksonLoginRegistration', new Login.BrooksonLoginRegistration())
                .component('brooksonLoginServiceLine', new Login.BrooksonLoginServiceLine())
                .component('brooksonLoginSuper', new Login.BrooksonLoginSuper())
                .component('brooksonLoginShareholder', new Login.BrooksonLoginShareholder())
                ;
        }
    }
}

Login.App.createModule(angular);