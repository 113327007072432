/// <reference path="../../_all.ts"/>
/// <reference path="../../shared/services/brookson.services.authentication.ts" />
/// <reference path="./../../shared/modules/brookson.ui.popup.ts" />
/// <reference path="../../shared/modules/brookson.enums.ts" />

module Login {
    export class BrooksonLoginForgottenPassword implements ng.IComponentOptions {
        public controller: Function = ForgottenPasswordCtrlController;
        public templateUrl: string = "src/app/login/views/forgottenPassword.html";
    }

    export interface AppSettings extends ng.IWindowService {
        appSettings: any;
    }

    class ForgottenPasswordCtrlController {
        static $inject = ['$state', 'authenticationSrv', 'brookson.ui.popup', 'contact.enums', 'brookson.utilities.date',"$window"];

        public username: string = "";
        public message: string = null;
        public smsMessage: string = "";
        public isForgottenId: boolean = false;
        public isForgottenPassword: boolean = false;
        public forgottenIdFirstName: string = "";
        public forgottenIdSurname: string = "";
        public forgottenIdEmail: string = "";
        public forgottenIdDob: Date;
        public isIntouch: boolean;


        constructor(
            private $state: ng.ui.IStateService,
            private authenticationSrv: Shared.IAuthenticationService,
            private popup: Shared.IBrooksonUiPopup,
            private enums: Shared.IContactEnums,
            private dateUtils: Shared.IBrooksonUtilitiesDate,
            private $window: AppSettings,
        ) { 
            if(this.$window.appSettings.isIntouchSite)
                {
                    this.enums.ContactInformation.CustomerServicesPhone = '01202 901951';
                }    
        }

        requestPasswordReset = (form): void => {
            if (form.$invalid) {
                this.message = "Please enter your username";
                return;
            }

            this.resetMessage();

            this.authenticationSrv.requestPasswordReset(this.username)
                .then((data) => {
                    if (data) {
                        this.popup.showInfoPopup(
                            'Reset link sent',
                            'An SMS was sent to your mobile phone number. Please follow the instructions in order to reset your password. If you have any complications please call us on ' + this.enums.ContactInformation.CustomerServicesPhone + ' for assistance.')
                            .then(() => {
                                this.$state.go('login.login');
                            });
                    } else {
                        this.message = 'We were unable to send you the instuctions to reset your password. Please call us on ' + this.enums.ContactInformation.CustomerServicesPhone + ' for assistance.';
                        return;
                    }
                })
                .catch((error) => {
                    this.message = 'We were unable to send you the instuctions to reset your password. Please call us on ' + this.enums.ContactInformation.CustomerServicesPhone + ' for assistance.';
                    return;
                });
        }

        requestForgottenClientId = (form, $event): void => {
            if (form.$invalid) {
                this.message = "All information is required to complete your request.";
                return;
            }
            this.resetMessage();  

            var convertedDOB = this.dateUtils.convertDateToMidnightGmt(this.forgottenIdDob);
            var dOBString = convertedDOB.toISOString();
           
            this.authenticationSrv.requestForgottenClientId(this.forgottenIdFirstName, this.forgottenIdSurname, this.forgottenIdEmail, dOBString)
                .then((data) => {
                    if (data) {
                        this.popup.showInfoPopup(
                            'Client ID sent',
                            'An SMS has been sent to your mobile phone number. Please use this Client ID as your username for Connect. If you have any complications, please call us on ' + this.enums.ContactInformation.CustomerServicesPhone + ' for assistance.'
                            )
                            .then(() => {
                                this.$state.go('login.login');
                            });
                        return;
                    } else {
                        this.message = 'We were unable to send your client ID. Please review your details and try again, if the problem persists please call us on ' + this.enums.ContactInformation.CustomerServicesPhone + ' for assistance.';
                        return;
                    }
                })
                .catch((error) => {
                    this.message = 'We were unable to send your client ID. Please call us on ' + this.enums.ContactInformation.CustomerServicesPhone + ' for assistance.';
                    return;
                });
        }

        hasForgottenId = (input: boolean): void => {
            this.resetMessage();
            this.isForgottenId = input;
            this.isForgottenPassword = !input;
        }

        hasForgottenPassword = (input: boolean): void => {
            this.resetMessage();
            this.isForgottenPassword = input;
            this.isForgottenId = !input;
        }

        goToLogin = (): void => {
            this.$state.go("login.login");
        }

        resetMessage = (): void => {
            this.message = null;
        }
    }
}