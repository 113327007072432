/// <reference path="../../_all.ts"/>
/// <reference path="../../shared/interfaces/brookson.interfaces.window.ts" />
/// <reference path="../../shared/services/brookson.services.member.ts" />
/// <reference path="../../shared/services/brookson.services.authentication.ts" />
/// <reference path="../../shared/modules/brookson.navigation.ts" />
/// <reference path="../../shared/models/member/ServiceLine.ts" />

module Login {
    export class BrooksonLoginServiceLine implements ng.IComponentOptions {
        public controller: Function = ServiceLineCtrlController;
        public templateUrl: string = "src/app/login/views/serviceLine.html";
    }

    class ServiceLineCtrlController {
        static $inject = ['$window', '$stateParams', 'memberSrv', 'authenticationSrv', 'brookson.navigation'];

        public selectedServiceLine: Shared.ServiceLine;
        public member: Shared.MemberModel;
        public serviceLines: Array<any> = [];
        public message: string = "";

        constructor(
            private $window: Window,
            private $stateParams: ng.ui.IStateParamsService,
            private memberSrv: Shared.IMemberService,
            private authenticationSrv: Shared.IAuthenticationService,
            private brooksonNavigation: Shared.IBrooksonNavigation
        ) { }

        $onInit = (): void => {
            this.member = this.memberSrv.getMember();
            this.serviceLines = this.member.serviceLines;
        }

        submit = (isValid: boolean): void => {
            if (isValid) {

                // Umbrella users should go to Connect 2.0
                if (this.selectedServiceLine.businessType.toLowerCase() === 'umbrella' && !this.$window.appSettings.umbEnabled) {
                    this.$window.location.href = this.$window.appSettings.connect2Url + '/Login.aspx?lo=a25';
                    return;
                }

                //update the user service active service line
                this.member.companyRef = this.selectedServiceLine.customerReference;
                this.memberSrv.setMember(this.member);
                this.selectedServiceLine.navReferral = this.member.navReferral;
                this.authenticationSrv.updateAccessTokenServiceLine(this.member.memberId, this.selectedServiceLine, this.member.refreshToken, this.member.apiKeySource)
                    .then(
                        (data): void => {
                            // If is leaver, go to self assessment
                            if (this.selectedServiceLine.isEligible) {
                                this.brooksonNavigation.personalTax.selfAssessment();
                                return;
                            }

                            if (this.$stateParams['ReturnUrl']) {
                                this.$window.location.href = this.$stateParams['ReturnUrl'];
                            } else {
                                this.brooksonNavigation.dashboard.main();
                            }
                        },
                        (err): void => {
                            this.message = err.data.error_description;
                        })
                    .catch((): void => { });
            }
        }
    }
}