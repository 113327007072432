/// <reference path="../../_all.ts"/>
/// <reference path="../../shared/services/brookson.services.authentication.ts" />

module Login {
    export class BrooksonLoginShareholder implements ng.IComponentOptions {
        public controller: Function = ShareholderLoginController;
        public templateUrl: string = "src/app/login/views/shareholder.html";
    }

    class ShareholderLoginController {
        static $inject = ["$state", "$stateParams", "authenticationSrv", "memberSrv", "serviceLineSrv", "brookson.navigation", "$window"];

        public registrationId: string = "";
        public username: string = "";
        public newPassword: string = "";
        public confirmPassword: string = "";
        public message: string = "";

        constructor(
            private $state: ng.ui.IStateService,
            private $stateParams: ng.ui.IStateParamsService,
            private authenticationSrv: Shared.IAuthenticationService,
            private memberSrv: Shared.IMemberService,
            private serviceLineSrv: Shared.IServiceLineService,
            private brooksonNavigation: Shared.IBrooksonNavigation,
            private $window: Window
        ) {
            
        }

        $onInit = (): void => {

        }

        goToLogin = (): void => {
            this.$state.go("login.login");
        }

        submitLogin = (isValid: boolean): void => {

            if (!isValid) {
                this.message = "Form invalid!";
                return;
            }

            if (this.newPassword !== this.confirmPassword){
                this.message = "Passwords do not match";
                return;
            }

            this.authenticationSrv.verifyShareholderLogin(this.username, this.registrationId, this.newPassword)
                .then((result) => {
                    if (result) {
                        let loginData: any = {
                            userName: this.username,
                            password: this.newPassword
                        };

                        this.authenticationSrv.login(loginData)
                            .then((result) => {
                                let member: Shared.MemberModel = this.memberSrv.setMember(result);
                                this.serviceLineSrv.getServiceLines(member)
                                    .then((lines) => {
                                        if (lines.length) {
                                            member.serviceLines = lines;

                                            var hasMultipleServiceLines = _.keys(_.groupBy(lines, 'customerReference')).length > 1;

                                            if (hasMultipleServiceLines) {
                                                this.$state.go("login.serviceLine", this.$stateParams);
                                            } else {
                                                member.businessType = lines[0].businessType;
                                                member.role = lines[0].role;
                                                member.companyRef = lines[0].customerReference;
                                                this.authenticationSrv.updateAccessTokenServiceLine(member.memberId, member.serviceLines[0], member.refreshToken, member.apiKeySource)
                                                    .then((data): void => {

                                                        // Umbrella users should go to Connect 2.0
                                                        if (member.businessType.toLowerCase() === 'umbrella' && !this.$window.appSettings.umbEnabled) {
                                                            this.$window.location.href = this.$window.appSettings.connect2Url + '/Login.aspx?lo=a25';
                                                            return;
                                                        }

                                                        if (member.role.toLowerCase() === "shareholder") {
                                                            this.brooksonNavigation.personalTax.selfAssessment();
                                                        } else {
                                                            this.brooksonNavigation.dashboard.main();
                                                        }

                                                    })
                                                    .catch((): void => { });
                                            }

                                            this.memberSrv.setMember(member);
                                        } else {
                                            this.message = "Unable to find service lines for user.";
                                        }
                                    }, (err): void => {
                                        this.message = err.error_description;
                                    });
                            });

                    } else {
                        this.message = "The credentials you provided are incorrect.";
                        return;
                    }
                },
                (error): void => {
                    this.message = error.error_description;
                }).then(() => {
                    return;
                });
        }


    }

}