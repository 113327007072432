/// <reference path="../../_all.ts"/>
/// <reference path="../../shared/services/brookson.services.member.ts" />
/// <reference path="../../shared/services/brookson.services.service-line.ts" />
/// <reference path="../../shared/services/brookson.services.authentication.ts" />
/// <reference path="./../../shared/modules/brookson.notifications.manager.ts"/>
/// <reference path="../../shared/modules/brookson.enums.ts" />
/// <reference path="../../shared/services/brookson.services.account.ts" />

module Login {
    export class BrooksonLoginRegistration implements ng.IComponentOptions {
        public controller: Function = RegistrationCtrlController;
        public templateUrl: string = "src/app/login/views/registration.html";
    }

    export interface AppSettings extends ng.IWindowService {
        appSettings: any;
    }
    
    class RegistrationCtrlController {
        static $inject = ['$state', 'memberSrv', 'serviceLineSrv', 'authenticationSrv', 'brookson.navigation', 'contact.enums',"$window", 'accountSrv'];

        public username: string = '';
        public registrationId: string = '';
        public password: string = '';
        public confirmPassword: string = '';
        public message: string = null;

        public strengthMessage: string = '';
        public strengthClass: string = '';
        public isWeakPassword: boolean = true;
        public hasTyped: boolean = false;
        public isCommonPassword: boolean =false;
        
        constructor(
            private $state: ng.ui.IStateService,
            private memberSrv: Shared.IMemberService,
            private serviceLineSrv: Shared.IServiceLineService,
            private authenticationSrv: Shared.IAuthenticationService,
            private brooksonNavigation: Shared.IBrooksonNavigation,
            private enums: Shared.IContactEnums,
            private $window: AppSettings,
            private accountSrv: Shared.IAccountService,
        ) { 
            if(this.$window.appSettings.isIntouchSite)
                {
                    this.enums.ContactInformation.CustomerServicesPhone = '01202 901951';
                }    
        }

        activateMember = () => {
            if (this.password !== this.confirmPassword) {
                this.message = 'The confirmation password must match.';
                return;
            }

            if (this.passwordStrengthValidation(this.password)) {
                this.message = null;
                this.authenticationSrv.activateMember(this.username, this.registrationId, this.password)
                    .then((data): void => {
                        if (data) {
                            this.authenticationSrv.login({
                                userName: this.username,
                                password: this.password
                            })
                            .then((result): void => {
                                let member: Shared.MemberModel = this.memberSrv.setMember(result);

                                if (member.isAdmin) {
                                    this.$state.go("login.super");
                                } else {
                                    this.serviceLineSrv.getServiceLines(member)
                                        .then((lines): void => {
                                            if (lines.length) {
                                                member.serviceLines = lines;
                                                if (lines.length > 1) {
                                                    this.$state.go("login.serviceLine");
                                                } else {
                                                    member.companyRef = lines[0].customerReference;
                                                    this.authenticationSrv.updateAccessTokenServiceLine(member.memberId, member.serviceLines[0], member.refreshToken, member.apiKeySource )
                                                        .then((data): void => {
                                                            this.brooksonNavigation.dashboard.main();
                                                        })
                                                        .catch((): void => { });
                                                }

                                                this.memberSrv.setMember(member);
                                            } else {
                                                this.message = "Login failed. Your password has been changed but we were unable to log you back in. Please call us on " + this.enums.ContactInformation.CustomerServicesPhone + " for assistance.";
                                            }
                                        }, (err): void => {
                                            this.message = err.error_description;
                                        });
                                }
                            },
                                (err): void => {
                                    this.message = "Login failed. Your password has been changed but we were unable to log you back in. Please call us on " + this.enums.ContactInformation.CustomerServicesPhone + " for assistance.";
                                });
                        } else {
                            this.message = 'Activation failed. Please call us on ' + this.enums.ContactInformation.CustomerServicesPhone + ' for assistance.';
                        }
                    });
            }
        }

        passwordStrengthValidation = (password: string): boolean => {
    
            let commonPasswords: string[] = [];

            this.accountSrv.getCommonPasswords(true).then((data) => {
                commonPasswords = data;
            });

            this.hasTyped = true;
            let strength = 0;


            if (password.length < 8) {
                this.message = 'Password must be a least 8 characters long.';
                return false;
            }

            // Check if the password is common
            if (commonPasswords.indexOf(password.trim()) !== -1) {
                this.strengthMessage = 'Weak (Common Password)';
                this.strengthClass = 'strength-weak';
                this.isWeakPassword = true;
                this.message = 'The password is too common or easy to guess. Please choose a stronger one';
                return false;
            }

            this.isCommonPassword = false;

            // Check password strength criteria
            if (password.length >= 8) strength++;
            if (/[A-Z]/.test(password)) strength++;
            if (/[a-z]/.test(password)) strength++;
            if (/[0-9]/.test(password)) strength++;
            if (/[\W]/.test(password)) strength++;

            // Set validity and strength messages based on criteria
            if (strength <= 2) {
                this.strengthMessage = 'Weak';
                this.strengthClass = 'strength-weak';
                this.isWeakPassword = true;
                this.message = 'Password must contain a combination of letters, numbers, and/or symbols';
                return false;
            } else if (strength === 3) {
                this.strengthMessage = 'Medium';
                this.strengthClass = 'strength-medium';
                this.isWeakPassword = false;
            } else if (strength > 3) {
                this.strengthMessage = 'Strong';
                this.strengthClass = 'strength-strong';
                this.isWeakPassword = false;
            }

            return true;
        }

        
        goToLogin = (): void => {
            this.$state.go("login.login");
        }
    }
}